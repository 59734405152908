<template>
  <div class="details">
    <div class="top">
      <div class="back-page" @click="toPage('/home?type=1')">
        <span class="iconfont icon-fanhui icon-button"></span>
        <div class="back">
          {{ $t('pushDetails.backPage') }}
        </div>
      </div>
      <div class="title">
        {{ params.title }}
      </div>
    </div>
    <div
      class="recent-time"
      :style="
        lang === 'ja'
          ? 'padding-left:10.2vw'
          : lang === 'zh' || lang === 'zhf'
          ? 'padding-left:10.5vw'
          : lang === 'en'
          ? 'padding-left:10.1vw'
          : ''
      "
    >
      <div class="left">{{ $t('pushDetails.recentPush') }}</div>
      <div class="right">{{ params.updateTime }}</div>
    </div>
    <div class="details-content">
      <div class="left-list" ref="mianscroll">
        <div v-for="(item, index) in leftList" :key="index">
          <div
            class="date"
            @click="scrollToTop(item, index)"
            :id="'list' + index"
            :class="itemStratIndex === index ? 'heigLight' : ''"
          >
            <div class="dian">·</div>
            <div class="date-time">
              <div>{{ extractYMD(item.updateTime) }}</div>
              <div class="date-title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div v-html="params.content" class="html news-content"></div>
        <div class="footer-button">
          <div class="button" @click="previousStep">
            <div
              class="left"
              :class="itemStratIndex === 0 ? 'noMore' : 'highlight'"
              v-if="!isHoverLeft"
              @mouseenter="handleMouseEnterSort(1)"
              @mouseleave="handleMouseLeaveSort(1)"
            >
              <span class="iconfont icon-zuojiantou icon-button"></span>
            </div>
            <div
              class="left"
              v-if="isHoverLeft"
              @mouseenter="handleMouseEnterSort(1)"
              @mouseleave="handleMouseLeaveSort(1)"
              :class="itemStratIndex === 0 ? 'noMore' : 'highlight hover'"
            >
              <span class="iconfont icon-zuojiantou icon-button"></span>
            </div>
            <div style="cursor: pointer;">{{ $t('pushDetails.previous') }}</div>
          </div>
          <div class="button" @click="next">
            <div style="cursor: pointer;">{{ $t('pushDetails.next') }}</div>
            <div
              class="right"
              :class="itemEndIndex === this.leftList.length ? 'noMore' : 'highlight'"
              v-if="!isHoverRight"
              @mouseenter="handleMouseEnterSort(2)"
              @mouseleave="handleMouseLeaveSort(2)"
            >
              <span class="iconfont icon-youjiantou icon-button"></span>
            </div>
            <div
              class="right hover"
              v-if="isHoverRight"
              :class="itemEndIndex === this.leftList.length ? 'noMore' : 'highlight hover'"
              @mouseenter="handleMouseEnterSort(2)"
              @mouseleave="handleMouseLeaveSort(2)"
            >
              <span class="iconfont icon-youjiantou icon-button"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDynamic } from '@/api/push'

export default {
  data() {
    return {
      lang: '',
      leftList: [],
      butType: 1,
      itemStratIndex: 0,
      itemEndIndex: 0,
      isHoverLeft: false,
      isHoverRight: false,
      params: {}
    }
  },
  created() {
    this.lang = localStorage.getItem('language') || 'ja'
  },
  watch: {
    '$i18n.locale'() {
      this.getDynamic(false)
    }
  },
  mounted() {
    this.getDynamic(true)
  },
  methods: {
    extractYMD(dateTimeStr) {
      // 使用split方法按照"-"和" "进行分割
      // 首先分割出年月日部分（"YYYY-MM-DD"），然后再忽略时间和剩余的部分
      const parts = dateTimeStr.split(' ')[0].split('-')

      // 确保我们得到了正确的部分数量
      if (parts.length === 3) {
        // 返回组合后的年月日字符串
        return parts[0] + '-' + parts[1] + '-' + parts[2]
      } else {
        // 如果格式不正确，返回空字符串或错误消息
        return ''
      }
    },
    bottomScrollClick(elementId) {
      this.$nextTick(() => {
        const element = document.getElementById(elementId)
        const element1 = document.getElementById('list' + (this.itemStratIndex - 1))
        // 获取其上一级元素
        var parent = element.parentNode

        // 获取其高度
        var height = parent.offsetHeight
        if (element) {
          // 计算目标元素到页面顶部的距离
          if (this.itemStratIndex === 0) {
            const scrollEl = this.$refs.mianscroll
            scrollEl.scrollTo({ top: Number(0), behavior: 'smooth' })
          } else if (this.itemStratIndex === 1) {
            const offsetTop = element.offsetTop
            const offsetTop1 = element1.offsetTop
            const scrollEl = this.$refs.mianscroll
            scrollEl.scrollTo({
              top: Number(offsetTop1 - height * 2.4),
              behavior: 'smooth'
            })
          } else {
            const offsetTop = element.offsetTop
            const offsetTop1 = element1.offsetTop
            const scrollEl = this.$refs.mianscroll
            scrollEl.scrollTo({
              top: Number(offsetTop1 - height * 2.2),
              behavior: 'smooth'
            })
          }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          // 使用平滑滚动效果滚动到目标元素
        }
      })
    },
    getDynamic() {
      getDynamic(this.lang).then(res => {
        this.leftList = res.data
        this.params = res.data[0]
        this.itemStratIndex = 0
        this.itemEndIndex = 0
        this.bottomScrollClick('list' + this.itemStratIndex)
      })
    },
    handleMouseEnterSort(e) {
      if (e === 1) {
        this.isHoverLeft = true
      } else {
        this.isHoverRight = true
      }
    },
    handleMouseLeaveSort(e) {
      if (e === 1) {
        this.isHoverLeft = false
      } else {
        this.isHoverRight = false
      }
    },
    toPage(e) {
      this.$router.go(-1)
    },
    previousStep() {
      if (this.itemStratIndex > 0) {
        this.params = this.leftList[--this.itemStratIndex]
        this.itemEndIndex = this.itemStratIndex
      } else {
        this.itemStratIndex = 0
      }
      this.bottomScrollClick('list' + this.itemStratIndex)
    },
    next() {
      if (this.itemStratIndex + 2 < this.leftList.length) {
        this.params = this.leftList[++this.itemStratIndex]
        this.itemEndIndex = this.itemStratIndex
      } else {
        this.params = this.leftList[this.leftList.length - 1]
        this.itemEndIndex = this.leftList.length
        this.itemStratIndex = this.leftList.length - 1
      }
      this.bottomScrollClick('list' + this.itemStratIndex)
    },
    scrollToTop(e, index) {
      this.itemStratIndex = index
      this.itemEndIndex = index + 1
      this.params = e
      this.bottomScrollClick('list' + this.itemStratIndex)
    }
  }
}
</script>
<style scoped lang="scss">
.details {
  padding-top: 72px;
  background-color: $bg-color;
  color: $light-color;
  padding-bottom: 200px;
  .top {
    padding: 118px 190px 100px 190px;
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
    .back-page {
      cursor: pointer;
      font-weight: bold;
      font-size: 28px;
      line-height: 46px;
      display: flex;
      align-items: center;
      .icon-button {
        font-size: 36px;
        margin-right: 4px;
      }
      .back {
        width: 252px;
      }
    }
    .title {
      min-height: 93px;
      //margin-bottom: 100px;
      font-weight: bold;
      font-size: 28px;
      line-height: 46px;
      margin-left: 148px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 99;
    }
  }
  .recent-time {
    padding: 0px 10.3vw;
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
    .left {
      width: 276px;
      font-weight: bold;
      font-size: 16px;
      line-height: 35px;
    }
    .right {
      margin-left: 164px;
      font-size: 16px;
      line-height: 35px;
      color: #86868b;
    }
  }
  .details-content {
    padding: 0 190px 0 166px !important;
    display: flex;
    align-items: flex-start;
    .left-list {
      width: 304px;
      margin-top: 30px;
      height: 532px;
      overflow-y: auto;
      scrollbar-width: none;
      //margin-left: 24px;
      .date {
        cursor: pointer;
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 35px;
        display: flex;
        align-items: flex-start;
        .dian {
          font-size: 16px;
          line-height: 35px;
          margin: 0 10px;
        }
        .date-time {
          height: 130px;
        }
      }
    }
    .right-content {
      margin-top: 12px;
      margin-left: 164px;
      width: 1100px;
      min-height: 358px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .footer-button {
        margin-top: 130px;
        width: 760px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        margin-left: 220px;
        .button {
          display: flex;
          align-items: center;
          .left {
            width: 54px;
            height: 54px;
            border-radius: 50%;
            cursor: pointer;
            //background: rgba(66, 66, 69, 0.72);
            margin-right: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-button {
              font-size: 25.5px;
              color: $light-color;
            }
          }
          .right {
            width: 54px;
            height: 54px;
            border-radius: 50%;
            cursor: pointer;
            //background: rgba(66, 66, 69, 0.72);
            margin-left: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-button {
              font-size: 25.5px;
              color: $light-color;
            }
          }
        }
      }
    }
  }
}
.heigLight {
  color: #bbb290;
}
.html {
  color: #f5f5f7 !important;
  font-weight: 400;
  line-height: 35px; /* 218.75% */
  text-indent: 0;
  font-size: 16px;
  white-space: pre-wrap;
  position: relative;
  z-index: 1;
  word-break: break-all;
}
.date-title {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
  height: 100px;
}
.highlight {
  background: rgba(66, 66, 69, 0.72);
}
.hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
    rgba(66, 66, 69, 0.72);
}
.noMore {
  opacity: 0.36;
  background: rgba(66, 66, 69, 0.72);
}
</style>
<style lang="scss">
.news-content {
  img {
    width: 100% !important;
    margin-bottom: 60px;
  }
  p {
    margin: 0 0 54px;
    font-size: 16px;
    color: #f5f5f7 !important;
    line-height: 25px;
    strong {
      color: #f5f5f7 !important;
      background-color: transparent !important;
    }
    span {
      color: #f5f5f7 !important;
      background-color: transparent !important;
    }
    img {
      //margin: 30px 0;
      //margin-left: -23px;
    }
    em {
      background-color: transparent !important;
    }
  }

  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-justify {
    text-align: justify;
  }
  .ql-video {
    width: 100%;
    object-fit: cover;
  }
}
</style>
